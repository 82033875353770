<template>
  <div class="assembly-page-form-body-files">
    <div
      class="assembly-page-form-body-file"
      v-for="count in filesAmount"
      :key="count.id"
    >
      <FileUpload
        accept="image/png, image/jpeg"
        @uploaded="onUploaded"
        @deleted="onDeleted"
      />
    </div>
  </div>
</template>

<script>
import FileUpload from './FileUpload.vue';

export default {
  name: 'Files',
  components: {
    FileUpload,
  },
  props: ['filesAmount'],
  data() {
    return {
      selectedFiles: [],
    };
  },
  methods: {
    onDeleted({ uuid }) {
      const itemIndex = this.selectedFiles.findIndex((item) => item === uuid);
      this.selectedFiles.splice(itemIndex - 1, 1);
      this.$emit('files', this.selectedFiles);
    },
    onUploaded({ uuid }) {
      this.selectedFiles = [...this.selectedFiles, uuid];
      this.$emit('files', this.selectedFiles);
    },
  },
};
</script>

<style lang="scss">
.assembly-page-form-body-files {
  display: flex;
  .assembly-page-form-body-file {
    display: flex;
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .assembly-page-form-body-files {
      justify-content: space-between;
    .assembly-page-form-body-file {
      input {
        width: 104px;
      }
    }
  }
}

@media (max-width: 375px) {
  .assembly-page-form-body-files {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
