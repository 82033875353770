import { axiosProfiClubApiWithCredentials } from '..';

export async function uploadFile(files) {
  const url = 'product-assembly/file';
  const response = await axiosProfiClubApiWithCredentials.post(url, files);
  return response.data ? response.data : null;
}

export async function createAssembly(data) {
  const url = 'product-assembly/assembly';
  const response = await axiosProfiClubApiWithCredentials.post(url, data);
  return response || null;
}
