<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="white"
    opacity="0.85"
    spinner-variant="primary"
  >
    <b-container
      fluid="sm"
      class="d-flex flex-wrap justify-content-center justify-content-lg-between"
    >
      <PageHeader class="mb-4 mt-4">
        Регистрация прибора в акции
      </PageHeader>
      <div class="assembly-page">
        <p class="assembly-page-warning">
          Для участия в акции заполните форму ниже.
          Обязательно прикрепите фото прибора,
          где виден серийный номер, его упаковки и фото объекта,
          на котором прибор смонтирован.
        </p>
        <div class="assembly-page-form">
          <b-form class="assembly-page-form-body">
            <ValidationProvider
              name="email"
              rules="email"
              v-slot="{ errors }"
            >
              <b-form-group
                :invalid-feedback="form.email.invalidFeedback"
                :state="form.email.state"
              >
                <b-form-input
                  v-if="emailFromJwt === null"
                  name="email"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  placeholder="Email"
                  type="email"
                  v-model="form.email.value"
                  :state="form.email.state"
                  @input="() => onEmailChange(errors)"
                />
              </b-form-group>
            </ValidationProvider>
            <QrReader @serialNumber="onSerialNumber"/>
            <ValidationProvider
              name="serialNumber"
              rules="serialNumber"
              v-slot="{ errors }"
            >
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                v-mask="'#### #### #### #### ####'"
                placeholder="Серийный номер прибора"
                v-model="form.serialNumber.value"
              />
              <span
                class="mt-2"
                style="color: #F5000C;"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <InputSuggestDropdown
              label-for="input-city"
              id="input-city"
              inputPlaceholder="Введите город"
              v-model="form.city.value"
              :state="form.city.state"
              :invalid-feedback="form.city.invalidFeedback"
              :isPending="!listCityIsLoading"
              :suggests="listCity"
              returnObjKey="value"
              @input="getCitiesList"
              @select="onSelectCity"
              v-slot="slotData"
            >
              {{ slotData.suggest.value }}<br/>
            </InputSuggestDropdown>
            <Files
              @files="onFilesUpdate"
              :filesAmount="3"
            />
          </b-form>
          <ToTheStars class="assembly-page-form-image"/>
        </div>
        <div class="assembly-page-button">
          <b-button
            class="align-self-center"
            @click="createAssembly()"
            :disabled="!isFormValid"
          >
            Зарегистрировать монтаж
          </b-button>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import InputSuggestDropdown from '../components/InputSuggestDropdown.vue';
import { getListCity } from '../utils';
import { createAssembly } from '../api/profi_club/product-assembly';
import PageHeader from '../components/PageHeader.vue';
import ToTheStars from '../assets/tothestars.svg';
import QrReader from '../components/QrReader.vue';
import Files from '../components/Files.vue';

extend('email', {
  ...email,
  message: 'Введите корректный Email',
});
extend('serialNumber', (value) => {
  const serialNum = value.replace(/\D+/g, '');
  if (serialNum.length === 16 || serialNum.length === 20) {
    return true;
  }
  return 'Введите корректный серийный номер';
});

export default {
  name: 'Assembly',
  components: {
    PageHeader,
    ToTheStars,
    InputSuggestDropdown,
    Files,
    ValidationProvider,
    QrReader,
  },
  data() {
    return {
      serialNumberFromComponent: null,
      isLoading: true,
      listCity: [],
      listCityIsLoading: true,
      form: {
        region: null,
        email: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        city: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        serialNumber: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        selectedFiles: [],
      },
    };
  },
  methods: {
    onEmailChange(errors) {
      if (errors.length !== 0) {
        // eslint-disable-next-line prefer-destructuring
        this.form.email.invalidFeedback = errors[0];
        this.form.email.state = false;
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.form.email.invalidFeedback = null;
        this.form.email.state = null;
      }
    },
    onSerialNumber(value) {
      this.form.serialNumber = value;
    },
    onFilesUpdate(value) {
      this.form.selectedFiles = value;
    },
    async createAssembly() {
      try {
        const resp = await createAssembly({
          email: this.form.email.value || this.emailFromJwt,
          serialNumber: this.serialNumber,
          city: this.form.city.value,
          region: this.form.region,
          photos: this.form.selectedFiles,
        });
        await this.$router.push({
          name: 'gift',
          query: {
            status: String(resp.status),
            response: JSON.stringify(resp.data),
          },
        });
      } catch (e) {
        await this.$router.push(
          {
            name: 'gift',
            query: {
              status: String(e.response.status),
              errorText: String(e.response.data),
            },
          },
        );
      }
    },
    async getCitiesList(value) {
      this.listCity = await getListCity(value);
      this.listCityIsLoading = false;
    },
    onSelectCity(value, obj) {
      this.form.city.value = value;
      this.form.region = obj.data.region;
    },
  },
  computed: {
    ...mapGetters('personalData', {
      emailFromJwt: 'email',
    }),
    isFormValid() {
      return (this.emailFromJwt !== null || (this.form.email.value !== null
          && this.form.email.value.length >= 5 && this.form.email.state !== false))
        && this.form.city.value !== null
        && this.form.serialNumber.value !== null
        && (this.serialNumber.length === 16 || this.serialNumber.length === 20)
        && this.form.selectedFiles.length === 3;
    },
    serialNumber() {
      if (this.form.serialNumber.value) {
        return this.form.serialNumber.value.replace(/\D+/g, '');
      }
      return '';
    },
  },
  async created() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.assembly-page {
  width: 100%;

  .assembly-page-warning {
    color: #404040;
  }

  .assembly-page-form {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .assembly-page-form-body {
      width: 45%;

      .form-control,
      .form-group {
        margin-top: 30px;
      }
    }
  }

  .assembly-page-button {
    width: 100%;
    display: flex;
    justify-content: center;

    .btn {
      width: 30%;
      min-width: 200px;
      margin: 50px 0 50px 0;
      background-color: #0050C8;
      gap: 10px;
      border-radius: 4px;
    }
  }
}

@media (max-width: 576px) {
  .assembly-page-form {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 996px) {
  .assembly-page-form {
    .assembly-page-form-body {
      width: 100% !important;
    }
  }
  .btn {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .assembly-page-form {
    justify-content: center !important;

    .assembly-page-form-image {
      display: none;
    }
  }
}
</style>
