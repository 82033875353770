<template>
  <div class="file-upload">
    <input
      v-if="!fileUploaded"
      @change="uploadFile"
      type="file"
      :accept="accept"
      class="file-upload-input"
    />
    <div
      v-else
      class="file-upload-image"
      @click="deleteFile"
    >
      <img
        class="file-upload-image-content"
        :src="this.fileImage"
        alt=""
      >
      <div class="file-upload-image-trash-wrapper">
        <b-icon variant="light" class="file-upload-image-trash-wrapper-image" icon="trash"/>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="file-upload-spinner"
    >
      <b-spinner
        class="file-upload-spinner-image"
        label="Spinning"
      />
    </div>
    <span v-if="fileName">
      {{ fileName | squeezeText(10) }}
    </span>
  </div>
</template>

<script>
import { uploadFile } from '../api/profi_club/product-assembly';

export default {
  name: 'FileUpload',
  props: {
    accept: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    uuid: null,
    fileName: null,
    isLoading: false,
    fileUploaded: false,
    fileImage: null,
  }),
  methods: {
    deleteFile() {
      this.$emit('deleted', { uuid: this.uuid, fileName: this.fileName });
      this.fileUploaded = false;
      this.fileImage = null;
      this.fileName = null;
      this.uuid = null;
    },
    async uploadFile(event) {
      const formData = new FormData();
      formData.append('file', event.target.files[0], event.target.files[0].name);
      this.isLoading = true;
      this.fileImage = URL.createObjectURL(event.target.files[0]);
      try {
        const resp = await uploadFile(formData);
        this.fileName = event.target.files[0].name;
        this.uuid = resp.uuid;
        this.$emit('uploaded', { uuid: this.uuid, fileName: this.fileName });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        this.fileUploaded = true;
      }
    },
  },
};
</script>

<style lang="scss">
.file-upload {
  margin-right: 30px;
  &-spinner {
    width: 104px;
    height: 104px;
    position: absolute;
    background-color: #F8F9FA;
    border-radius: 4px;
    &-image {
      position: absolute;
      top: 38px;
      left: 33px;
    }
  }
  display: flex;
  flex-direction: column;
  position: relative;
  &-image {
    position: relative;
  }
  &-image-content {
    width: 104px;
    height: 104px;
    border: 1px solid #ADB5BD;
    padding: 9px;
    border-radius: 4px;
  }
  &-image-trash-wrapper {
    width: 104px;
    height: 104px;
    top: 0;
    padding: 9px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    z-index: 10;
    visibility: hidden;
    &-image-trash-wrapper-image {
      top: 50px;
      left: 42px;
    }
  }
  &-input {
    width: 104px;
    height: 104px;
    &::-webkit-file-upload-button {
      visibility: hidden;
      width: 104px;
      height: 104px;
    }

    &::before {
      content: '+ \a Загрузите \a фото';
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 104px;
      height: 104px;
      border: 1px dashed #ADB5BD;
      border-radius: 4px;
      background-color: #F8F9FA;
      padding: 15px;
      cursor: pointer;
    }
  }

}
@media (max-width: 1200px) {
  .file-upload-image {
    cursor: pointer;
    .file-upload-image-content {
      z-index: 4;
    }
    .file-upload-image-trash-wrapper {
      visibility: visible;
      .file-upload-image-trash-wrapper-image {
        transition: 0.3s;
        z-index: 5;
        position: absolute;
        top: 50px;
        left: 42px;
        visibility: visible;
      }
    }
  }
}
@media (min-width: 1200px) {
  .file-upload-image:hover {
    cursor: pointer;
    .file-upload-image-content {
      z-index: 4;
    }
    .file-upload-image-trash-wrapper {
      visibility: visible;
      .file-upload-image-trash-wrapper-image {
        transition: 0.3s;
        z-index: 5;
        position: absolute;
        top: 50px;
        left: 42px;
        visibility: visible;
      }
    }
  }
}
@media (max-width: 576px) {
  .file-upload {
    margin-right: 0;
  }
}
</style>
